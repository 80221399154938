import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ProjectInfo, Row } from './ProjectSettingsPreviewSection.styles';

export interface ProjectSettingsPreviewSectionProps {
  name: string;
  nameInitials: string;
  photoUrl?: string | null;
  canEdit: boolean;
  onEdit: () => void;
}

export function ProjectSettingsPreviewSection({
  name,
  nameInitials,
  photoUrl,
  canEdit,
  onEdit,
}: ProjectSettingsPreviewSectionProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="The basics" columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <Row data-mobile={isMobile}>
          <ProjectInfo>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              {name}
            </RMText>
            {canEdit && <RMLink onClick={onEdit}>Edit</RMLink>}
          </ProjectInfo>

          <RMAvatar placeholder={nameInitials} shape="circle" background="primary" src={photoUrl} size="large" />
        </Row>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
