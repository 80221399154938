import { RMInput } from '@/components/RMInput/RMInput';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';

import { ProfileEditForm } from '../../forms/profile-edit.form';

import { Container, Form, Header } from './SubjectEdit.styles';

interface ProjectEditProps {
  form: ProfileEditForm;
}

export function SubjectEdit({ form }: ProjectEditProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container data-mobile={isMobile}>
      <Header>
        <RMText type="sans" size="s" color="on-surface-secondary">
          Who is this project about?
        </RMText>
      </Header>

      <Form>
        <InputContainer form={form} path="firstName">
          {(props) => <RMInput id="firstName" label="First Name" {...props} />}
        </InputContainer>

        <InputContainer form={form} path="lastName">
          {(props) => <RMInput id="lastName" label="Last Name" {...props} />}
        </InputContainer>
      </Form>
    </Container>
  );
}
