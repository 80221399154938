import { useMemo } from 'react';
import { IMask } from 'react-imask';
import { UserStatus } from '@remento/types/user';

import { usePersonQuery } from '@/services/api/person';
import { usePersonUserQuery } from '@/services/api/user/user.service.hook';

import { RecipientSettingsPreviewSection } from '../components/RecipientSettingsPreviewSection/RecipientSettingsPreviewSection';

interface RecipientSettingsPreviewContainerProps {
  personId: string;
  onEdit: () => void;
  onManageNotification: () => void;
}

export function RecipientSettingsPreviewContainer({
  personId,
  onEdit,
  onManageNotification,
}: RecipientSettingsPreviewContainerProps) {
  const personUserQuery = usePersonUserQuery(personId);
  const personQuery = usePersonQuery(personId);

  const email = personUserQuery.data?.communicationChannels.email ?? '';
  const phone = useMemo(
    () =>
      personUserQuery.data?.communicationChannels.phone
        ? IMask.pipe(personUserQuery.data?.communicationChannels.phone.number, { mask: '(000) 000-0000' })
        : null,
    [personUserQuery.data?.communicationChannels.phone],
  );

  return (
    <RecipientSettingsPreviewSection
      name={personQuery.data?.name?.full}
      email={email}
      phone={phone}
      canEdit={personUserQuery.data?.status === UserStatus.PLACEHOLDER}
      onEdit={onEdit}
      onManageNotification={onManageNotification}
    />
  );
}
