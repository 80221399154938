import { useCallback, useEffect, useState } from 'react';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { toast } from '@/components/RMToast/RMToast';
import { getFormValue, isFormDirty, isFormValid } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { useProjectCoverUrl, useProjectInitials, useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';

import { ProjectEdit } from '../components/ProjectEdit/ProjectEdit';
import { createProjectEditForm } from '../states/project-edit.form';

export interface ProjectEditContainerProps {
  open: boolean;
  projectId: string | null;
  onClose: () => void;
}

export function ProjectEditContainer({ open, projectId, onClose }: ProjectEditContainerProps) {
  const [form, setForm] = useState(() => createProjectEditForm());
  const { assetService, entityCacheManagerService, projectService } = useServices();

  const projectQuery = useProjectQuery(open ? projectId : null);
  const projectInitials = useProjectInitials(projectQuery.data);
  const coverUrl = useProjectCoverUrl(projectQuery.data);

  const [changedPhoto, setChangedPhoto] = useState<{ url: string; handle: string } | null>(null);

  const closeAndResetDialog = useCallback(() => {
    onClose();
    setChangedPhoto(null);
    setForm(createProjectEditForm());
  }, [onClose]);

  const handlePhotoChange = useCallback((photo: FilestackHandle) => {
    setChangedPhoto(photo);
    toast('Photo uploaded', 'dialog-panel-toast');
  }, []);

  const handleSave = useCallback(async () => {
    const formValues = getFormValue(form);
    if (!isFormValid(form) || formValues == null) {
      return;
    }

    try {
      if (!projectQuery.data) {
        console.warn('Project not loaded yet');
        return;
      }

      const project = projectQuery.data;
      const mutations: EntityMutation[] = [];

      // Update photo
      if (changedPhoto) {
        const newAsset = await assetService.createFilestackAsset({
          entity: { id: project.id, type: EntityType.PROJECT },
          type: AssetType.IMAGE,
          handle: changedPhoto.handle,
        });

        mutations.push(...projectService.createSetCoverImageMutation(project, newAsset.id));
      }

      mutations.push(...projectService.createSetProjectNameMutation(project, formValues.name));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      closeAndResetDialog();
      toast('Project updated');
    } catch (error) {
      captureException(error, true);
      toast('Failed to update project', 'dialog-panel-toast', 'error');
    }
  }, [
    projectQuery.data,
    assetService,
    changedPhoto,
    form,
    projectService,
    entityCacheManagerService,
    closeAndResetDialog,
  ]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
  }, [closeAndResetDialog]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (changedPhoto || isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
  }, [changedPhoto, form, closeAndResetDialog]);

  // Update the form value
  useEffect(() => {
    if (!projectQuery.data) {
      return;
    }
    setInputValue(form, 'name', projectQuery.data.name ?? '');
  }, [form, projectQuery.data]);

  const photoSrc = changedPhoto?.url ?? coverUrl;

  return (
    <RMDialogPanel.Root open={open} onClose={handleClose}>
      <RMDialogPanel.Title onClose={handleClose}>Project details</RMDialogPanel.Title>
      <RMDialogPanel.Content>
        {projectQuery.data && (projectQuery.data?.coverAssetId == null || coverUrl != null) ? (
          <ProjectEdit
            form={form}
            photoUrl={photoSrc}
            projectNameInitials={projectInitials}
            onPhotoChange={handlePhotoChange}
          />
        ) : null}
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" fullWidth onClick={handleSave} autoLoading>
          Save
        </RMButton>
      </RMDialogPanel.Actions>

      <RMConfirmationModal
        open={closeConfirmationOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSaveChanges}
        onCancel={handleDiscardChanges}
      />
    </RMDialogPanel.Root>
  );
}
