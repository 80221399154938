import { useCallback, useEffect, useState } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { toast } from '@/components/RMToast/RMToast';
import { getFormValue, isFormDirty, isFormValid } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';

import { SubjectEdit } from '../components/SubjectEdit/SubjectEdit';
import { createProfileEditForm } from '../forms/profile-edit.form';

export interface ProjectEditContainerProps {
  open: boolean;
  projectId: string | null;
  onClose: () => void;
}

export function SubjectEditContainer({ open, projectId, onClose }: ProjectEditContainerProps) {
  const [form, setForm] = useState(() => createProfileEditForm());
  const { entityCacheManagerService, personService } = useServices();

  const projectQuery = useProjectQuery(open ? projectId : null);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.subjectPersonIds[0]);

  const [changedPhoto, setChangedPhoto] = useState<{ url: string; handle: string } | null>(null);

  const closeAndResetDialog = useCallback(() => {
    onClose();
    setChangedPhoto(null);
    setForm(createProfileEditForm());
  }, [onClose]);

  const handleSave = useCallback(async () => {
    const formValues = getFormValue(form);
    if (!isFormValid(form) || formValues == null) {
      return;
    }

    try {
      if (!personQuery.data) {
        console.warn('Person not loaded yet');
        return;
      }

      const person = personQuery.data;
      const mutations: EntityMutation[] = [];

      mutations.push(
        ...personService.createSetPersonNameMutation(person, {
          first: formValues.firstName,
          last: formValues.lastName,
        }),
      );

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      closeAndResetDialog();
      toast('Subject updated');
    } catch (error) {
      captureException(error, true);
      toast('Failed to update subject', 'dialog-panel-toast', 'error');
    }
  }, [form, personQuery.data, personService, entityCacheManagerService, closeAndResetDialog]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
  }, [closeAndResetDialog]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (changedPhoto || isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
  }, [changedPhoto, form, closeAndResetDialog]);

  // Update the form value
  useEffect(() => {
    if (!personQuery.data) {
      return;
    }
    setInputValue(form, 'firstName', personQuery.data.name?.first ?? '');
    setInputValue(form, 'lastName', personQuery.data.name?.last ?? '');
  }, [form, personQuery.data, projectQuery.data]);

  return (
    <RMDialogPanel.Root open={open} onClose={handleClose}>
      <RMDialogPanel.Title onClose={handleClose}>Project subject</RMDialogPanel.Title>
      <RMDialogPanel.Content>{personQuery.data ? <SubjectEdit form={form} /> : null}</RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" fullWidth onClick={handleSave} autoLoading>
          Save
        </RMButton>
      </RMDialogPanel.Actions>

      <RMConfirmationModal
        open={closeConfirmationOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSaveChanges}
        onCancel={handleDiscardChanges}
      />
    </RMDialogPanel.Root>
  );
}
